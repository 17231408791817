import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageSplashScreen from "./components/SplashScreen";
import "./index.css";
import { SnackbarProvider } from "notistack";
import { RecoilRoot } from "recoil";
import ModalProvider from "mui-modal-provider";
import ThemeManager from "./middlewares/ThemeManager";
import Index from "./pages/home";
import Ten24Policy from "./pages/1024/policy";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RecoilRoot>
    {/* The theme provider is added at the root level  */}
    <ThemeManager>
      <ModalProvider>
        <SnackbarProvider
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <PageSplashScreen>
            <BrowserRouter basename="/">
              <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/1024/privacy-policy" element={<Ten24Policy />} />
              </Routes>
            </BrowserRouter>
          </PageSplashScreen>
        </SnackbarProvider>
      </ModalProvider>
    </ThemeManager>
  </RecoilRoot>
);
