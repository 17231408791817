import React from "react";
import { Stack, Typography } from "@mui/material";
import Header from "../home/header";
import ColorHeading from "../../components/ColorHeading";
import Footer from "../home/footer";
const TERMS = [
  {
    title: "1. Information We Collect",
    text: "",
  },
  {
    title: "a. Firebase Authentication",
    text: "For analytics and leaderboard, we utilize Google Firebase with anonymous signin. No personal information is stored or used in this process besides the device identifier.",
  },
  {
    title: "b. User Data",
    text: "User provided data (such as display name and highscore). The display name is not associated with any of the user's personally identifiable information or device id. ",
  },
  {
    title: "c. Application Data",
    text: "The game stores essential information on users' device using cache, vital for the game's functionality.",
  },
  {
    title: "2. Data Deletion Requests",
    text: "",
  },
  {
    title: "",
    text: "Anonymous user accounts are automatically deleted after inactivity of at least 6 months. Hence, users do not need to send any data deletion requests for the removal of their provided information. ",
  },

  {
    title: "3. Storage and Cache",
    text: "",
  },
  {
    title: "",
    text: "The game employs storage and cache, necessary for its functionality. Without this data, users cannot play the game.",
  },

  {
    title: "4. Updates and Modifications",
    text: "",
  },
  {
    title: "",
    text: "This Privacy Policy may be periodically updated, and users are encouraged to revisit it regularly to stay informed about any changes.",
  },
  {
    title: "5. Consent",
    text: "",
  },
  {
    title: "",
    text: "By downloading and playing the game, users acknowledge they have read and understood the terms within this privacy policy.",
  },
  {
    title: "6. Additional Provisions",
    text: "",
  },
  {
    title: "a. Security Measures",
    text: "We implement industry-standard security measures to protect user data.",
  },
];
export default function Ten24Policy() {
  return (
    <Stack
      flex={1}
      sx={{
        bgcolor: "#030712",
        scrollBehavior: "smooth",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Header />

      <Stack
        alignItems={"start"}
        flex={1}
        sx={{ maxWidth: "1280px", my: "120px" }}
      >
        <ColorHeading
          text1="Privacy Policy"
          text2=""
          text2sx={{ color: "#48F" }}
          mainsx={{ fontSize: "28px", alignItems: "start" }}
          text1sx={{ color: "#fff" }}
        />
        <ColorHeading
          text1=""
          text2="1024: Block Breaker 2D"
          mainsx={{ fontSize: "26px", alignItems: "start" }}
          text2sx={{ color: "#48F" }}
          text1sx={{ color: "#fff" }}
        />
        <Typography sx={{ color: "#fff" }}>
          Last updated: February 2nd, 2025.
        </Typography>
        <Typography sx={{ color: "#fff" }}>
          For further information or inquiries regarding this Privacy Policy,
          please contact us at ab@zexware.com
        </Typography>
        <br />
        <br />
        <Typography sx={{ color: "#fff" }}>
          In this Privacy Policy, we outline the information we collect, how it
          is used, and your choices concerning your data for the "1024: 2D Block
          Breaker" mobile application (game), herein referred as "the Game".
        </Typography>
        <br />
        <Stack spacing={"24px"}>
          {TERMS.map((t) => (
            <>
              {t.title && (
                <Typography
                  sx={{ px: t.text ? "14px" : undefined, color: "#48F" }}
                  color={"primary"}
                  fontSize={16}
                  fontWeight={500}
                >
                  {t.title}
                </Typography>
              )}
              {t.text && (
                <Typography
                  sx={{ pl: "28px", color: "#fff" }}
                  fontSize={16}
                  fontWeight={400}
                >
                  {t.text}
                </Typography>
              )}
            </>
          ))}
        </Stack>
      </Stack>

      {/* Footer  */}
      <Footer />
      {/* <GoToTop /> */}
    </Stack>
  );
}
